// @font-face {
//   font-family: "Noto Sans KR";
//   font-weight: 500;
//   src: url("NotoSansKR.otf") format('opentype');
// }

html,
body {
  height: 100%;
}

#root {
  height: 100%;
}

* {
  box-sizing: border-box;
}

.font-xl-title {
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 700;
  font-size: 60px;
  line-height: 87px;
  letter-spacing: -0.02em;
}

.font-l-title {
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 70px;
  letter-spacing: -0.02em;
}

.font-m-title {
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 46px;
  letter-spacing: -0.02em;
}

.font-s-title {
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 35px;
  letter-spacing: -0.02rem;
}
.font-xs-title {
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: -0.02em;
  & * {
    font-family: Noto Sans KR;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: -0.02em;
  }
}

.px-16-b {
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 23px;
  letter-spacing: -0.02rem;
  color: #4f4f4f;
}
.px-16-r {
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 23px;
  letter-spacing: -0.02rem;
  color: #4f4f4f;
}
.px-14-b {
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.02rem;
  color: #4f4f4f;
}
.px-14-r {
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.02rem;
  color: #4f4f4f;
}
.px-12-b {
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 17px;
  letter-spacing: -0.02em;
  color: #4f4f4f;
}
.px-12-r {
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 17px;
  letter-spacing: -0.02em;
  color: #4f4f4f;
}

.input-grey {
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  color: #c9c9c9;
}
.input-black {
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  color: #232323;
}
.input-red {
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  color: #e54658;
}

//
:root {
  --strock-darken: rgba(0, 0, 0, 0.12);
  // --strock-darken: rgba(186, 186, 186, 0.08);
  --mono-1: #333333;
  --mono-2: #4f4f4f;
  --mono-3: #828282;
  --mono-4: #bdbdbd;
  --mono-5: #e0e0e0;
  --mono-6: #f2f2f2;
  --a-purple-1: #6200ea;
  --a-purple-2: #651fff;
  --a-purple-3: #0047ab;
  --a-purple-4: #b388ff;
  --a-red-1: #d50000;
  --a-red-2: #ff1744;
  --a-red-3: #ff5252;
  --a-red-4: #ff8a80;
  --a-red-5: #e54658;
  --a-blue-1: #2962ff;
  --a-blue-2: #2979ff;
  --a-blue-3: #448aff;
  --a-blue-4: #82b1ff;
  --b-red-1: #e54658;
  --b-red-2: #fb0049;
  --b-red-3: #fd4154;
  --b-red-4: #e54658;
  --b-red-5: #ff6a7c;
  --b-red-6: #f29c98;
  --b-orange-1: #e54658;
  --b-orange-2: #fd7036;
  --b-orange-3: #f2994a;
  --b-orange-4: #fda27e;
  --b-orange-5: #f2c94c;
  --b-orange-6: #f7f6af;
  --b-green-1: #219653;
  --b-green-2: #27ae60;
  --b-green-3: #6fcf97;
  --b-green-4: #77ea7e;
  --b-green-5: #a2ec8e;
  --b-green-6: #d0f2b1;
  --b-blue-1: #4324ff;
  --b-blue-2: #2f80ed;
  --b-blue-3: #56ccf2;
  --b-blue-4: #1ea5fc;
  --b-blue-5: #87b9e7;
  --b-blue-6: #a1e8ff;
  --b-purple-1: #9b51e0;
  --b-purple-2: #bb6bd9;
  --b-purple-3: #8962f8;
  --b-purple-4: #a990dd;
}

.Modal {
  // position: absolute;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.64);
}
